@import "../../../../../../../assets/styles/core/viewports";

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 89;
    overflow: scroll;

  &__text-content {
    color: #000;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    margin-top: 25px;
    padding: 0 15px;  
  }

  &__text-content-grey {
    color: #52585F !important;
  }

  &__text-content-psdfSuccess,
  &__text-content-psdfWithAdditionalPaymentSuccess {
    color: #000;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    margin-top: 25px;
    padding: 0 15px;
    p:nth-child(2) {
      background-color: rgb(241, 245, 247);
      border-radius: 8px;
      padding:4px;
    }
  }

  &__text-content-ctxrmSuccess {
    color: #000;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    margin-top: 25px;
    padding: 0 15px;
    p:nth-child(4) {
      background-color: rgb(241, 245, 247);
      border-radius: 8px;
      padding:4px;
    }
  }

  &__text-content-popUp {
    color: #000;
    font-size: 14px;
    line-height: 0px;
    text-align: center;   
    padding: 0;
  }

  &__text-sub-content {
    color: #000;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    margin-top: 25px;
    padding: 0 15px;
  }

  &__alert {
    position: relative;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    border-radius: 8px;
    background-color: #dbffed;
    padding: 15px;

    @include tablet {
      padding-left: 39px;
    }

    &--error {
      background-color: #fee6e8;
    }

    &--success {
      background-color: #dbffed;
    }

    &--warning {
      background-color: yellow;
    }

    svg {
      position: absolute;
      display: block;
      top: 13px;
      left: 15px;
    }
  }

  &__backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    background-color: rgba(21, 20, 49, 0.3);
    overflow: auto;
    user-select: none;
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 30px 0;
    width: 540px;
    padding-bottom: 20px;
    pointer-events: all;
    user-select: text;
    padding: 40px 20px;
    border-radius: 15px;
    background-color: #fff;

    @include tablet {
      width: 100%;
      max-width: 540px;
      margin: 15px;
      padding: 30px 15px;
    }
  }

  &__content__wide {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 30px 0;
    width: 660px;
    padding-bottom: 20px;
    pointer-events: all;
    user-select: text;
    padding: 40px 20px;
    border-radius: 15px;
    background-color: #fff;

    @include tablet {
      width: 100%;
      max-width: 540px;
      margin: 15px;
      padding: 30px 15px;
    }
  }

  &__content-popup {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 30px 0;
    width: 540px;
    line-height: 0;
    pointer-events: all;
    user-select: text; 
    border-radius: 15px;
    background-color: #fff;

    @include tablet {
      width: 100%;
      max-width: 540px;
      margin: 15px;   
    }
  }

  &__container {
    position: absolute;
    opacity: 1;
    transform: scale(1);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100%;
    pointer-events: none;
    user-select: none;
    z-index: 20;

  }

  &__header {
    display: flex;
    align-items: center;
    padding-bottom: 30px;

    &__title {
      font-weight: bold;
      font-size: 22px;
      text-align: center;
      width: 100%;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;

    > * {
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__close-blue-button {
    position: absolute;
    bottom: 28px;
    right: 190px;
    width: 84px;
    height: 23px;
    cursor: pointer;
    transition: all 0.15s;

    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 20px;
      height: 20px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
    }
  }

  &__close-extra-blue-button {
      position: absolute;
      bottom: 28px;
      right: 95px;
      width: 64px;
      height: 23px;
      cursor: pointer;
      transition: all 0.15s;
  
    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 20px;
      height: 20px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
    }
  }

  &__close-button {
    position: absolute;
    top: 16px;
    right: 20px;
    width: 12px;
    height: 12px;
    cursor: pointer;
    transition: all 0.15s;

    @include tablet {
      top: 10px;
      right: 15px;
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 20px;
      height: 20px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:hover {
      opacity: 0.7;
    }

    svg {
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  &__close-button-grey {
    svg path {
      fill:#52585F !important;
    }
  }

  &.fade-enter {
    .modal__backdrop {
      opacity: 0;
    }

    .modal__content {
      transform: scale(0.9);
      opacity: 0;
    

    }
  }

  &.fade-enter-active {
    .modal__backdrop {
      opacity: 1;
      transition: all 0.1s ease-in-out;
    }

    .modal__content {
      transform: scale(1);
      opacity: 1;
      transition: all 0.1s ease-in-out;
    }
  }

  &.fade-exit {
    .modal__backdrop {
      opacity: 1;
    }

    .modal__content {
      transform: scale(1);
    }
  }

  &.fade-exit-active {
    .modal__backdrop {
      opacity: 0;
      transition: all 0.1s ease-in-out;
    }

    .modal__content {
      transform: scale(0.9);
      opacity: 0;
      transition: all 0.1s ease-in-out;
    }
  }
}
