@import '../../styles/core/viewports';

.transfer-request-page {
  h1 {
    font-size: 36px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 39px;
    text-align: center;
    padding: 60px 40px;

    @include tablet {
      padding: 20px 0;
      font-size: 24px;
      line-height: 26px;
    }
  }
  form > div {
    padding: 60px 190px;
   
    @include tablet {
      padding: 30px 20px;
    }
  }

  form > div:first-child {
    padding: 0;
  }
}

.tabList {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.tabItem {
  cursor: pointer;
  width:50%;
  text-align:center;

  p {
    font-size: 25px;
    font-weight: 600;
  }

  @include mobile {
    padding: 10px;
  }

  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.activeTab {
  background-color: #dee5ed;
}


