@import '../../styles/core/viewports';

.transfer-success-page {
  h1 {
    font-size: 36px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 39px;
    text-align: center;
    padding: 60px 40px;

    @include tablet {
      padding: 20px 0;
      font-size: 24px;
      line-height: 26px;
    }
  }
}
