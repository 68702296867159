$switch-primary-color: #1a1f2f;
$switch-active-color: #0087f7;
$switch-disabled-color: #9395a9;

.switch {
  display: inline-flex;
  align-items: center;

  span + span {
    padding-left: 10px;
    cursor: pointer;
  }

  input {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    left: -99999px;
    outline: none;

    + span {
      color: $switch-primary-color;
      display: flex;
      width: 40px;
      border-radius: 12px;
      border: 1px solid currentColor;
      padding: 4px;
      cursor: pointer;
      transition: border-color 0.3s;

      &:after {
        content: '';
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: currentColor;
        transform: translate(0, 0);
        left: 0;
        position: relative;
        transition: all 0.3s;
      }
    }

    &:checked {
      + span {
        color: $switch-active-color;

        &::after {
          transform: translate(-100%, 0);
          left: 100%;
        }
      }
    }

    &[disabled] {
      + span {
        color: $switch-disabled-color;
        cursor: not-allowed;

        + span {
          cursor: not-allowed;
          color: $switch-disabled-color;
        }
      }
    }
  }

  &:active,
  &:focus {
    outline: none;
  }
}
