@import "~normalize.css/normalize.css";
@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,400;0,800;0,900;1,400;1,500&display=swap");

@import "./core/_viewports.scss";

// @import "../fonts/Harmonia-Sans-W01-Regular.ttf";
// @font-face {
//   font-family: "HarmoniaRegular";
//   src: local("HarmoniaRegular"), url("../fonts/Harmonia-Sans-W01-Regular.ttf") format("truetype");
//   font-weight: normal;
// }

@font-face {
  font-family: "Harmonia";
  src: local("HarmoniaRegular"), url("../fonts/HarmoniaSansW1G-Regular.otf") format("opentype");
  font-weight: normal;
}

// @import "../fonts/HarmoniaSansW1G-Regular.otf";
// @font-face {
//   font-family: "HarmoniaRegular";
//   src: local("HarmoniaRegular"), url("../fonts/HarmoniaSansW1G-Regular.otf") format("opentype");
//   font-weight: normal;
// }  

// @font-face {
//   font-family: "SourceSansPro";
//   src: local("SourceSansPro"), url("../fonts/SourceSansPro-Regular.ttf") format("truetype");
//   font-weight: normal;
// }  



body {
  margin: 0;  
  font-family: "Harmonia";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.4;
}

* {
  box-sizing: border-box;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

body,
html,
#root {
  height: 100%;
}

.pt-8 {
  padding-top: 80px;
}

.tac {
  text-align: center;
}

.mt-0 {
  margin-top: 0;
}

.mb-2 {
  margin-bottom: 20px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-6 {
  margin-bottom: 60px;
}

.mb-4 {
  margin-bottom: 40px;
}

.mt-4 {
  margin-top: 40px;
}

.mt-8 {
  margin-top: 90px;
}

.mb-md-2 {
  @include tablet {
    margin-bottom: 20px;
  }
}
.mt-md-2 {
  @include tablet {
    margin-top: 20px;
  }
}

.d-flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.error {
  color: #f35b69;
}
