.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
 
  position: absolute;
}

.tooltiptext{
    background-color: #f1f5f7;
    border: 1px solid black;
    color: black;
    width: 100px;
    font-size: 16px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptext {
  border: 1px solid black;
  width: 120px;
  bottom: 100%;
  left: 50%;
  margin-left: -60px; /* Use half of the width (120/2 = 60), to center the tooltip */
}