@import '../../../assets/styles/core/viewports';

.cookies-consent {
  &__modal {
    text-align: left;
  }
  &__item {
    display: flex;
  }
  &__row {
    padding: 20px 0;
    background-color: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    box-shadow: 0 -15px 64px 0 rgba(21, 22, 46, 0.1);

    > div {
      display: flex;
      align-items: center;

      @include tablet {
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }

    p {
      margin: 0;
      font-size: 14px;
      line-height: 20px;
      padding-right: 25px;

      @include tablet {
        width: 100%;
      }

      a {
        color: inherit;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }

    button {
      white-space: nowrap;
      margin-left: 20px;

      @include tablet {
        margin-top: 20px;
        margin-left: 0;
      }
    }
  }
}
