@import '../../styles/core/viewports';

.react-pdf__Page,
.react-pdf__Document {
  background-color: #fff;
}

.react-pdf__Page {
  canvas {
    margin: 0 auto;
  }
}

.react-pdf__Document {
  max-height: 520px;
  overflow: auto;

  @include mobile {
    max-height: 300px;
  }
}

.transfer-preview-page {
  h1 {
    font-size: 36px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 39px;
    text-align: center;
    padding: 60px 40px;

    @include tablet {
      padding: 20px 0;
      font-size: 24px;
      line-height: 26px;
    }
  }

  &--pdf {
    border-radius: 20px;
    background-color: #f1f5f7;
    padding: 60px 95px;
    min-width: 520px;

    @include tablet {
      padding: 30px 20px;
      min-width: auto;
    }
  }

  &--signin {
    max-width: 920px;
    border-radius: 20px;
    background-color: #f1f5f7;
    padding: 50px 95px 35px;
    text-align: center;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;

    @include tablet {
      padding: 20px;
      font-size: 14px;
    }

    &-control-number {
      margin-top: 15px;
      font-size: 40px;
      letter-spacing: 0;
      line-height: 50px;
      text-align: center;

      @include tablet {
        font-size: 24px;
        line-height: 28px;
      }
    }

    &-content {
      border-radius: 10px;
      background-color: #ffffff;
      box-shadow: 0 0 30px 0 rgba(23, 46, 64, 0.03);
      padding: 35px 65px;
      margin-bottom: 35px;
    }

    p {
      color: #74758a;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 18px;
      margin: 30px 0 0;
    }

    strong {
      color: #000;
    }
  }
}
